import axios from "../../plugins/axios";
import Vue from 'vue';
export default {
    namespaced: true,

    state: {
        terms: [],
    },

    mutations: {
        STORE_SAVED_TERMS(state, data) {
            state.terms = data;
        },

        ADD_NEW_TERMS(state, data) {
            state.terms.unshift(data);
        },

        POP_TERM(state, index) {
            state.terms.splice(index, 1);
        },

        UPDATE_TERM(state, { index, body }) {
            Vue.set(state.terms, index, body);
        }
    },

    actions: {
        all({ commit }) {
            return new Promise((resolve, reject) => {
                axios
                    .get("admin/taxonomies/all")
                    .then((res) => {
                        Vue.$log.info(res);
                        commit('STORE_SAVED_TERMS', res.data.terms)
                        resolve(res);
                    })
                    .catch((err) => reject(err));
            });
        },

        
        terms(context, term = null) {
            return new Promise((resolve, reject) => {
                axios
                .get(`admin/taxonomies/terms/${term}`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => reject(err));
            });
        },

        termsWithCount(context, {params}) {
            return new Promise((resolve, reject) => {
                axios
                    .get("taxonomies/posts-count", { params })
                    .then((res) => {
                        Vue.$log.info(res);
                        resolve(res);
                    })
                    .catch((err) => reject(err));
            });
        },

        add({ commit }, body) {
            Vue.$log.info('Code came here');
            return new Promise((resolve, reject) => {
                axios
                    .post("admin/taxonomies/add", body)
                    .then((res) => {
                        Vue.$log.info(res);
                        commit('ADD_NEW_TERMS', res.data.term)
                        resolve(res);
                    })
                    .catch((err) => reject(err));
            });
        },

        edit({ getters, commit }, { id, body }) {
            Vue.$log.info('Update term id: ' + id);

            return new Promise((resolve, reject) => {
                axios
                    .patch(`admin/taxonomies/update/${id}`, body)
                    .then((res) => {
                        Vue.$log.info(res);

                        let termIndex = getters['getTerms'].findIndex((item) => {
                            return item.id === id;
                        });

                        commit('UPDATE_TERM', {
                            index: termIndex,
                            body: {
                                id: id,
                                name: body.name,
                                slug: body.slug,
                                parent: body.parent,
                                description: body.description,
                            }
                        });

                        resolve(res);
                    })
                    .catch((err) => reject(err));
            });
        },

        delete(context, id) {
            Vue.$log.info(id);
            return new Promise((resolve, reject) => {
                axios
                    .delete(`admin/taxonomies/delete/${id}`)
                    .then((res) => {
                        Vue.$log.info(res);
                        resolve(res);
                    })
                    .catch((err) => reject(err));
            });
        }
    },

    getters: {
        getTerms(state) {
            return state.terms;
        }
    },
};
