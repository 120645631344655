<template>
    <div class="row justify-content-center pt-4">
        <div class="col-lg-6 col-md-6">
            <img
                :src="bannerUrl"
                alt="Not found"
                width="100%"
            />
        </div>
        <div class="col-12">
            <p :class="messageClass">
                <slot>
					Not found!
				</slot>
            </p>
        </div>
    </div>
</template>

<script>
export default {
	props: {
		messageClass: {
			type: String,
			default: "text-muted text-center mt-3 display-7",
		},		
		
	},
	data(){
		return {
			bannerUrl: require('@/assets/svg/not-found.svg'),
		}
	}
};
</script>

<style>
</style>