import axios from "../../plugins/axios";
import Vue from 'vue';
import eventBus from "@/eventBus";
export default {
    namespaced: true,

    state: {
        allowedMimeTypes: "image/png, image/jpg, image/jpeg",
        selectedFile: null,

        uploadProgress: 80,
        fileSelectOptions: null,
    },

    mutations: {
        SELECT_FILE(state, file) {
            Vue.$log.info(file);
            state.selectedFile = file
        },

        DESELECT_FILE(state) {
            state.selectedFile = null;
        },

        SET_UPLOAD_PROGRESS(state, value) {
            state.uploadProgress = value;
        },

        SET_FILE_SELECT_OPTIONS(state, options) {
            state.fileSelectOptions = options
        }
    },

    actions: {
        all(context , { params }) {
            return new Promise((resolve, reject) => {
                axios
                    .get("admin/files", { params })
                    .then((res) => {
                        Vue.$log.info(res);
                        resolve(res);
                    })
                    .catch((err) => reject(err));
            });
        },

        /**
         * 
         * @param {Object} context the store object
         * @param {Object} body the payload
         * @returns Promise
         */
        add({ commit, getters }, body) {
            return new Promise((resolve, reject) => {
                axios
                    .post("admin/files", body, {
                        headers: {
                            'content-type': 'multipart/form-data'
                        },
                        onUploadProgress: e => {
                            if (e.lengthComputable) {
                                let progress = Math.round((e.loaded / e.total) * 100);
                                commit('SET_UPLOAD_PROGRESS', progress);
                                Vue.$log.info(getters.getUploadProgress);
                            }
                        }
                    })
                    .then((res) => {
                        Vue.$log.info(res);
                        resolve(res);
                    })
                    .catch((err) => {
                        Vue.$log.info(err);
                        reject(err)
                    });
            });
        },

        update(c, body) {
            return new Promise((resolve, reject) => {
                axios
                    .patch(`admin/files/${body.id}`, body)
                    .then((res) => {
                        Vue.$log.info(res);
                        resolve(res);
                    })
                    .catch((err) => {
                        Vue.$log.info(err);
                        reject(err)
                    });
            });
        },

        delete(c, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`admin/files/${id}`)
                    .then((res) => {
                        Vue.$log.info(res);
                        resolve(res);
                    })
                    .catch((err) => {
                        Vue.$log.info(err);
                        reject(err)
                    });
            });
        },

        openFileModal({ commit }, options) {
            Vue.$log.info(options);
            commit('SET_FILE_SELECT_OPTIONS', options);
            eventBus.$emit('toggle-file-modal');
        },

        insertFile({ getters, commit }) {
            return new Promise((resolve, reject) => {
                Vue.$log.info(getters['get_file']);
                let file = getters['get_file'];
                if (eventBus.$emit(getters['getFileSelectOptions'].receivingTicket, file)) {
                    commit('SET_FILE_SELECT_OPTIONS', null);
                    commit('DESELECT_FILE');
                    resolve('Inserted');
                } else {
                    reject("Couldn't inserted. Please try again!");
                }
            })
        }
    },

    getters: {
        getAllowedMimeTypes(state) {
            return state.allowedMimeTypes;
        },

        get_file(state) {
            return state.selectedFile;
        },

        getUploadProgress(state) {
            return state.uploadProgress;
        },

        getFileSelectOptions(state) {
            return state.fileSelectOptions;
        }
    },
};
