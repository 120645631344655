<template>
    <div class="bg-gray-100">
        <DefaultNavbar class="d-md-none"></DefaultNavbar>
        <div class="container-lg container-fluid py-4">
            <!-- <h1 class="sticky-top">hh</h1> -->
            <!-- <div class="">
                <div class="sticky-top p-5 bg-danger"></div>
                <div class="sticky-top p-5 bg-primary"></div>
                <div class="p-5 bg-success"></div>
            </div> -->
            <div class="row">
                <div class="col-lg-3 col-md-4 d-md-block d-none pr-lg-2">
                    <!-- Content here -->
                    <SideBar>
                        <VerticalNav />
                        <br>
                        <PopularPosts postType="post" title="Popular Posts" :limit="8"/>
                    </SideBar>
                </div>
                <div class="col-lg-6 col-md-8">
                    <!-- Content here -->
                    <router-view></router-view>
                </div>
                <div class="col-lg-3 d-lg-block d-none pl-md-2">
                    <SideBar>
                        <SearchFormWidget />
                        <br>
                        <RecentPosts postType="article" title="Recent Articles" :limit="14" />
                    </SideBar>
                </div>
            </div>
        </div>

        <DefaultFooter />
    </div>
</template>
  
<script>
import DefaultFooter from "@/components/footers/default-footer.vue";
import SideBar from "@/components/public/sidebars/default-sidebar.vue";

export default {
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: "Articles",
        // all titles will be injected into this template
        titleTemplate: "%s | Alpha Science Lab",
    },
    components: {
        DefaultNavbar: () => import("@/components/navbars/default-navbar.vue"),
        DefaultFooter,
        SideBar,

        RecentPosts: () => import("@/components/public/widgets/recent-posts.vue"),
        PopularPosts: () => import("@/components/public/widgets/popular-posts.vue"),
        VerticalNav: () => import("@/components/public/widgets/vertical-navbar.vue"),
        // TaxonomiesListWidget : () => import("@/components/public/widgets/taxonomies-list.vue"),
        SearchFormWidget: () => import("@/components/public/widgets/search-form.vue"),
    },
};
</script>
  
<style lang="scss">
.sidebar {
    position: sticky !important;
    top: 0 !important;
}
</style>