<template>
    <div class="d-flex w-100 min-vh-100">
        <div
            id="dashboardWarningOverlay"
            ref="dashboardWarningOverlay"
            class="position-fixed d-md-none row justify-content-center align-content-center vh-100 overflow-hidden"
            style="
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                z-index: 9999999999;
                background-color: rgba(55, 55, 55, 0.2);
                backdrop-filter: blur(20px);
            "
        >
            <div class="col-10">
                <div class="card">
                    <div class="card-body text-center">
                        <font-awesome-icon
                            icon="fa-solid fa-circle-info"
                            class="text-primary mt-1 mb-3"
                            style="font-size: 3rem"
                        />
                        <p class="text-center">Dashboard is not supposed to be used by small screen devices. Use a large-screen device for a proper experience. However, you can continue if it's necessary.</p>
                        <b-button
                            variant="primary"
                            :to="{ name: 'home' }"
                            block
                        >
                            <font-awesome-icon
                                icon="fa-solid fa-globe"
                                class="mr-1"
                            />
                            Back to Public View
                        </b-button>
                        <button
                            class="btn btn-outline-primary btn-block"
                            @click="closeDashboardWarningOverlay"
                        >
                            <font-awesome-icon
                                icon="fa-solid fa-right-to-bracket"
                                class="mr-1"
                            />
                            Continue to Dashboard
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <DashboardSidebar
            :width="sideBarWidth"
            :widthCollapsed="sideBarWidthCollapsed"
        ></DashboardSidebar>
        <div
            class="bg-light ml-auto admin-view-wrap position-relative min-vh-100"
            :style="'width: calc(100% - ' + sidebarWidth + ')'"
        >
            <DashboardNavbar></DashboardNavbar>
            <Transition name="fade" mode="out-in">
                <!-- Content here -->
                <div
                    style="min-height: calc(100vh - 130px); overflow-x: hidden;"
                >
                    <router-view :key="$route.path"></router-view>
                </div>
            </Transition>
            <DashboardFooter></DashboardFooter>
        </div>
        <MediaPopup />
    </div>
</template>

<script>
import DashboardNavbar from "@/components/navbars/dashboard-navbar.vue";
import DashboardSidebar from "@/components/admin/sidebars/dashboard-sidebar.vue";
import DashboardFooter from "@/components/footers/dashboard-footer.vue";

import MediaPopup from "@/components/admin/media/media-popup.vue";

export default {
    metaInfo: {
        // title: "Dashboard",
        titleTemplate: "%s | Dashboard",
    },
    components: {
        DashboardNavbar,
        DashboardSidebar,
        DashboardFooter,
        MediaPopup,
    },
    data() {
        return {
            sideBarWidth: "240px",
            sideBarWidthCollapsed: "50px",
        };
    },
    computed: {
        sidebarWidth() {
            return this.$store.getters.getIsAdminSidebarCollapsed
                ? this.sideBarWidthCollapsed
                : this.sideBarWidth;
        },
    },

    methods: {
        toggleSidebar() {
            this.isCollapsed = !this.isCollapsed;
        },

        collapse(value) {
            this.$log.info(value);
            this.isCollapsed = value;
        },

        closeDashboardWarningOverlay() {
            this.$refs.dashboardWarningOverlay.style.display = "none";
            this.$store.commit(
                "TOOGLE_ADMIN_SIDEBAR",
                !this.$store.getters.getIsAdminSidebarCollapsed
            );
        },
    },
};
</script>

<style lang="scss">
@import url("vue-multiselect/dist/vue-multiselect.min.css");
.multiselect,
.multiselect__input,
.multiselect__single {
    font-size: 0.875rem;
}

.multiselect__tag {
    color: $dark;
    background: $light;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
    background: $danger;
}

.multiselect__placeholder {
    color: $gray-600;
}

.multiselect__option--highlight {
    background: $primary;
}

.multiselect__option--highlight:after {
    background: $primary;
}

.multiselect__option--selected.multiselect__option--highlight {
    background: $success;
}

.multiselect__option--selected.multiselect__option--highlight:after {
    background: $success;
}

.multiselect__option--group-selected.multiselect__option--highlight {
    background: $success;
}

.multiselect__option--group-selected.multiselect__option--highlight:after {
    background: $success;
}

.dashbaord-page-container {
    min-height: calc(100vh - 0px);
}

.admin-view-wrap {
    transition: 300ms;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: 5px;
}

.admin-view-wrap::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.admin-view-wrap::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
.admin-view-wrap::-webkit-scrollbar-thumb {
    background: red;
    border-radius: 10px;
}
</style>