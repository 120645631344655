import axios from "../../plugins/axios";
import Vue from 'vue';
export default {
    namespaced: true,

    state: {

    },

    mutations: {

    },

    actions: {

        /**
         * Get all comments.
         * 
         * @returns {Promise}
         */
        getAll() {
            return new Promise((resolve, reject) => {
                axios
                    .get("admin/comments")
                    .then((res) => {
                        Vue.$log.info(res);
                        resolve(res);
                    })
                    .catch((err) => reject(err));
            });
        },

        /**
         * Add new comment.
         * 
         * @param {Object} context 
         * @param {Object} body Payload
         * @returns {Promise}
         */
        add(context, body) {
            Vue.$log.info(body);
            return new Promise((resolve, reject) => {
                axios
                    .post("admin/comments/add", body)
                    .then((res) => {
                        Vue.$log.info(res);
                        resolve(res);
                    })
                    .catch((err) => {
                        Vue.$log.info(err);
                        reject(err)
                    });
            });
        },

        update(context, { body, id }) {
            return new Promise((resolve, reject) => {
                axios
                    .patch(`admin/comments/${id}`, body)
                    .then((res) => {
                        Vue.$log.info(res);
                        resolve(res);
                    })
                    .catch((err) => {
                        Vue.$log.info(err);
                        reject(err)
                    });
            });
        },

        delete(context, id) {
            Vue.$log.info(id);
            return new Promise((resolve, reject) => {
                axios
                    .delete(`admin/comments/${id}`)
                    .then((res) => {
                        Vue.$log.info(res);
                        resolve(res);
                    })
                    .catch((err) => reject(err));
            });
        }
    },

    getters: {

    },
};
