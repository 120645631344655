import axios from "../../plugins/axios";
import Vue from 'vue';
export default {
    namespaced: true,

    state: {

    },

    mutations: {

    },

    actions: {
        all() {
			Vue.$log.info('Code came here, Journal all');
            return new Promise((resolve, reject) => {
                axios
                    .get("admin/papers")
                    .then((res) => {
                        Vue.$log.info(res);
                        resolve(res);
                    })
                    .catch((err) => reject(err));
            });
        },

        add(context, body) {
            Vue.$log.info('Code came here');
            return new Promise((resolve, reject) => {
                axios
                    .post("admin/papers", body)
                    .then((res) => {
                        Vue.$log.info(res);
                        resolve(res);
                    })
                    .catch((err) => reject(err));
            });
        },

        edit(context, id) {
            Vue.$log.info('Code came here', id);
            return new Promise((resolve, reject) => {
                axios
                    .get(`admin/papers/edit/${id}`)
                    .then((res) => {
                        Vue.$log.info(res);
                        resolve(res);
                    })
                    .catch((err) => reject(err));
            });
        },

		update(context, {body, id}) {
            Vue.$log.info('Code came here');
            return new Promise((resolve, reject) => {
                axios
                    .patch(`admin/papers/${id}`, body)
                    .then((res) => {
                        Vue.$log.info(res);
                        resolve(res);
                    })
                    .catch((err) => reject(err));
            });
        },

        delete(context, id){
            Vue.$log.info(id);
            return new Promise((resolve, reject) => {
                axios
                    .delete(`admin/papers/${id}`)
                    .then((res) => {
                        Vue.$log.info(res);
                        resolve(res);
                    })
                    .catch((err) => reject(err));
            });
        }
    },

    getters: {

    },
};
