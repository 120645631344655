<template>
    <div class="">
        <slot>
            
        </slot>
    </div>
</template>

<script>

export default {
    components: {

    },

    data() {
        return {
        };
    },
};
</script>

<style>
</style>