import axios from "axios";
const baseURL = process.env.VUE_APP_API_BASE_URL;

// export const baseURLFileServer = "https://cdn.digitalhishab.com/uploadFile";

let Axios = axios.create({
	baseURL: baseURL,
	timeout: 10000,
	headers:{
		"Access-Control-Allow-Origin": "*",
		'Content-Type': "application/vnd.api+json",
		'Accept': "application/vnd.api+json",
	}
});
		
Axios.interceptors.request.use(function (config) {
	config.headers.Authorization = "Bearer " + localStorage.getItem('idToken');
	return config;
});

export default Axios;