<template>
    <div>
        <!-- Header -->
        <div class="row align-content-center no-gutters media-content-header">
            <div class="d-none d-md-inline-block col-auto">
                <b-button-toolbar key-nav>
                    <b-button-group>
                        <button class="btn btn-sm btn-light">
                            <fa icon="fa-solid fa-border-all" />
                        </button>
                        <button class="btn btn-sm btn-light">
                            <fa icon="fa-solid fa-table-list" />
                        </button>
                    </b-button-group>
                </b-button-toolbar>
            </div>
            <div class="d-none d-md-inline-block col-lg-2 px-1 px-md-0 mt-2 mt-md-0">
                <select class="media-type-select form-control form-control-sm">
                    <option selected value="image">Image</option>
                    <!-- <option selected value="">All Types</option>
                    <option value="video">Video</option>
                    <option value="audio">Audio</option>
                    <option value="pdf">PDF</option> -->
                </select>
            </div>
            <div class="d-none d-md-inline-block col-lg-2 px-1 px-md-0 mt-2 mt-md-0">
                <select class="media-date-select form-control form-control-sm">
                    <option selected>All Time</option>
                    <!-- <option value="1">Last Month</option>
                    <option value="2">Last Year</option> -->
                </select>
            </div>
            <!-- <div class="col-lg-2 px-1 px-md-0 mt-2 mt-md-0">
                <input
                    type="text"
                    class="form-control form-control-sm"
                    id="media-search-input"
                    placeholder="Search..."
                    v-model="searchMedia"
                />
            </div> -->
            <div class="col"></div>

            <!-- <button class="btn btn-success btn-sm" @click="toggleMediaUpload">
                <font-awesome-icon icon="fa-solid fa-plus" class="mr-1"  />Add New
            </button> -->
            <b-button-toolbar key-nav aria-label="Toolbar with button groups" class="mb-md-0 mb-4">
                <b-button-group>
                    <template v-for="(tab, i) in tabs">
                        <b-button v-if="$can(tab.ability[0], tab.ability[1])" :key="i" :variant="isCurrentTab(tab.value) ? 'success' : 'light'
                            " size="sm" class="px-lg-3 px-4 mt-lg-0 mt-4" @click="setTab(tab.value)">
                            <small class="mr-1">
                                <fa :icon="tab.icon" />
                            </small>
                            <span class="">
                                {{ tab.title }}
                            </span>
                        </b-button>
                    </template>
                </b-button-group>
            </b-button-toolbar>
        </div>

        <!-- Library -->
        <MediaTabLibrary v-if="isCurrentTab('media-library')" :is_popup="is_popup" />

        <!-- Upload -->
        <MediaTabUpload v-else @changeTab="changeTab" />
    </div>
</template>

<script>
import eventBus from "@/eventBus";
import { bytesToMB, processName, bytesToSize } from "@/utils/utilities";
import moment from "moment";
export default {
    components: {
        MediaTabLibrary: () =>
            import("@/components/admin/media/media-tab-library.vue"),
        MediaTabUpload: () =>
            import("@/components/admin/media/media-tab-upload.vue"),
    },
    props: {
        is_popup: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            processName,
            bytesToMB,
            bytesToSize,
            moment,

            searchMedia: "",
            // currentTab: "media-upload",
            currentTab: "media-library",
            tabs: [
                {
                    title: "Library",
                    value: "media-library",
                    icon: "fa-solid fa-grip-vertical",
                    ability: ["index", "file"],
                },
                {
                    title: "Upload",
                    value: "media-upload",
                    icon: "fa-solid fa-cloud-arrow-up",
                    ability: ["create", "file"],
                },
            ],
        };
    },

    methods: {
        toggleMediaUpload() {
            this.$log.info("toggleMediaUpload");
            eventBus.$emit("show-media-upload");
        },

        setTab(tab) {
            this.currentTab = tab;
        },

        isCurrentTab(tab) {
            return tab === this.getCurrentTab;
        },

        changeTab(tab) {
            this.currentTab = tab;
        },
    },

    computed: {
        getCurrentTab() {
            return this.currentTab;
        },
    },
};
</script>

<style lang='scss'>
$media-min-height: 450px;
$media-header-height: 60px;

.media-content-header {
    border-bottom: 1px solid lightgray;
    height: $media-header-height;
}

.media-library {
    min-height: $media-min-height;

    .media-content-wrap {
        max-height: 410px;
        overflow-y: auto;

        .media-thumb-wrap {
            // margin: 2px;
            padding: 6px;

            .media-thumb {
                transition: 100ms;
                border: 2px solid transparent;
                border-radius: 5px;

                img {
                    max-width: 100%;
                    aspect-ratio: 1 / 1;
                    object-fit: cover;
                    transition: 300ms;
                }
            }

            &.selected {
                .media-thumb {
                    background-color: $gray-200;
                    border-color: $gray-300;
                }
            }
        }
    }

    .media-details-wrap {
        border-left: 1px solid lightgray;
        max-height: $media-min-height;
        overflow-y: auto;
    }
}

.media-upload {
    border: 2px dashed lightgray;
    background: white;
    text-align: center;
    // min-height: 250px;
    height: $media-min-height;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mediaFileUploadForm {
    height: 100%;
    width: 100%;
}
</style>
