import Vue from 'vue';
import Vuex from 'vuex';
// import eventBus from '@/eventBus';
// modules import
import auth from './modules/auth';
import user from './modules/user';
import member from './modules/member';
import post from './modules/post';
import contact from './modules/contact';
import mailer from './modules/mailer';
import event from './modules/event';
import file from './modules/file';
import taxonomy from './modules/taxonomy';
import comment from './modules/comment';
import paper from './modules/paper';
import option from './modules/option';
import staticData from './modules/staticData';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth, user, member, post, contact, mailer, event, file, taxonomy, comment, paper, option, staticData,
  },
  strict: process.env.NODE_ENV !== 'production',
  state: {
    isAdminSidebarCollapsed: false,
    ui: {
      card: {
        loadingBlur: '0.2rem',
        loadingOpacity: '0.99rem',
        loadingSpinnerVariant: 'primary',
      }
    },
    departments: [
      {
        value: "eee",
        text: "Electrical and Electronic Engineering",
        disabled: false,
      },
      { value: "ce", text: "Civil Engineering", disabled: false },
      {
        value: "cse",
        text: "Computer Science and Engineering",
        disabled: false,
      },
    ],
    batches: [
      { value: 1, session: "2008-09", text: "1st", disabled: true },
      { value: 2, session: "2009-10", text: "2nd", disabled: true },
      { value: 3, session: "2010-11", text: "3rd", disabled: true },
      { value: 4, session: "2011-12", text: "4th", disabled: false },
      { value: 5, session: "2012-13", text: "5th", disabled: false },
      { value: 6, session: "2013-14", text: "6th", disabled: false },
      { value: 7, session: "2014-15", text: "7th", disabled: false },
      { value: 8, session: "2015-16", text: "8th", disabled: false },
      { value: 9, session: "2016-17", text: "9th", disabled: false },
      { value: 10, session: "2017-18", text: "10th", disabled: false },
      { value: 11, session: "2018-19", text: "11th", disabled: false },
      { value: 12, session: "2019-20", text: "12th", disabled: false },
      { value: 13, session: "2020-21", text: "13th", disabled: false },
      { value: 14, session: "2021-22", text: "14th", disabled: false },
    ],
    contactSubjects: [
      { value: 2, text: "General enquiry", disabled: false },
      { value: 3, text: "Membership related", disabled: false },
      { value: 4, text: "Project related", disabled: false },
      { value: 5, text: "Donation related", disabled: false },
      { value: 6, text: "Sponsorship related", disabled: false },
      { value: 7, text: "Event related", disabled: false },
      { value: 8, text: "Member's enquiry", disabled: false },
      { value: 9, text: "Account related issues", disabled: false },

      // 'Others' is the default.
      { value: 1, text: "Others", disabled: false },
    ],
    textEditorOptions: [
      "paragraph",
      "bold",
      "italic",
      "strike",
      "media",
      "youtube",
      "code",
      "codeBlock",
      "unsetAllMarks",
      "clearNodes",
      "bulletList",
      "orderedList",
      "blockquote",
      "horizontalRule",
      "lineBreak",
      "undo",
      "redo",

      "textAlign",
      "subscript",
      "superscript",
      "underline",
      "link",

      "characterCount",
    ],
    socials: [
      {
        name: "website",
        icon: "fa-solid fa-globe",
      },
      {
        name: "facebook",
        icon: "fa-brands fa-facebook",
      },
      {
        name: "twitter",
        icon: "fa-brands fa-twitter",
      },
      {
        name: "linkedin",
        icon: "fa-brands fa-linkedin",
      },
      {
        name: "github",
        icon: "fa-brands fa-github",
      },
      {
        name: "bitbucket",
        icon: "fa-brands fa-bitbucket",
      },
      {
        name: "researchGate",
        icon: "fa-brands fa-researchgate",
      },
    ],
  },
  mutations: {
    TOOGLE_ADMIN_SIDEBAR(state, value) {
      state.isAdminSidebarCollapsed = value;
    }
  },
  actions: {
    adminAlert(context, data) {
      let text = data.text || 'Success';
      let status = data.status || "Success";
      let toaster = data.toaster || 'b-toaster-bottom-right';
      let variant;

      if (data.variant) {
        variant = data.variant;
      } else {
        switch (status.toLowerCase()) {
          case 'success':
            variant = 'success'
            break;
          case 'error':
            variant = 'danger'
            break;
          case 'attention':
            variant = 'warning'
            break;
          case 'info':
            variant = 'info'
            break;
          default:
            variant = 'light'
            break;
        }
      }

      const vm = new Vue();
      vm.$bvToast.toast(text, {
        title: status,
        variant: variant,
        solid: true,
        href: "",
        toaster: toaster,
      });
    }

  },
  getters: {
    getDepartments(state) {
      return state.departments;
    },

    getBatches(state) {
      return state.batches;
    },

    getContactSubjects(state) {
      return state.contactSubjects;
    },

    getIsAdminSidebarCollapsed(state) {
      return state.isAdminSidebarCollapsed;
    },

    getTextEditorOptions(state) {
      return state.textEditorOptions
    },

    getSocials(state) {
      return state.socials;
    }
  }
})
