import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
import store from '../store';
import Ability from '../services/ability';
Vue.use(VueRouter)

Vue.use(VueMeta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
});

const checkDashboardAbility = (to, from, next) => {
  let toGuard = to.meta['ability'];
  // let fromGuard = from.meta['ability'];
  if (Ability.can(toGuard[0], toGuard[1])) {
    next();
  }
  // else if (fromGuard && Ability.can(fromGuard[0], fromGuard[1])) {
  //   next(from.path);
  // } 

  else {
    // next({ name: '404' });
    next({ name: 'dashboard.overview' });
  }
}

const routes = [
  // ========== Public routes ==============
  {
    path: '/',
    name: 'home',
    component: () => import('../views/pages/Public/Home.vue'),
    meta: {
      package: "Alpha Science Lab",
    }
  },
  // {
  //   path: '/brainiac',
  //   name: 'brainiac',
  //   component: () => import('../views/temp/brainiac.vue'),
  //   meta: {
  //     package: "Brainiac",
  //   }
  // },
  {
    path: '/brainiac/:event',
    name: "event",
    component: () => import('../views/temp/event.vue'),
    meta: {
      pageName: "Event Details"
    },
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/pages/Public/About.vue'),
    meta: {
      pageName: "About"
    },
  },
  {
    path: '/feed/:id',
    name: "feed.single",
    component: () => import('../views/pages/Public/Single/single-post.vue'),
    meta: {
      layout: 'default',
      pageName: "Feed",
    },
  },
  {
    path: '/feed',
    name: 'feed',
    component: () => import('../views/pages/Public/Feed.vue'),
    meta: {
      layout: 'dual-sidebar',
      pageName: "Feeds"
    },
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/pages/Public/Search.vue'),
    meta: {
      pageName: "Search"
    },
  },
  {
    path: '/members',
    name: 'members.all',
    component: () => import('../views/pages/Public/Members.vue'),
    meta: {
      pageName: "All Members"
    },
  },
  {
    path: '/alumni',
    name: 'alumni.all',
    component: () => import('../views/pages/Public/Alumni.vue'),
    meta: {
      pageName: "Alumni"
    },
  },
  {
    path: '/members/:username',
    name: 'members.single',
    component: () => import('../views/pages/Public/Member.vue'),
    meta: {
      pageName: "Member Details"
    },
  },
  {
    path: '/hall-of-fame',
    name: 'hallOfFame',
    component: () => import('../views/pages/Public/HallOfFame.vue'),
    meta: {
      pageName: "Contact"
    },
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/pages/Public/Contact.vue'),
    meta: {
      pageName: "Contact"
    },
  },
  {
    path: '/articles',
    name: 'articles',
    component: () => import('../views/pages/Public/Article.vue'),
    meta: {
      layout: 'right-sidebar',
      pageName: "Articles"
    },
  },
  {
    path: '/articles/:id',
    name: "articles.single",
    component: () => import('../views/pages/Public/Single/single-article.vue'),
    meta: {
      layout: 'default',
      pageName: "article.single",
    },
  },
  {
    path: '/projects/:id',
    name: "projects.single",
    component: () => import('../views/pages/Public/Single/single-project.vue'),
    meta: {
      layout: 'default',
      pageName: "project.single",
    },
  },
  {
    path: '/category/:name',
    name: "category",
    component: () => import('../views/pages/Public/Category.vue'),
    meta: {
      layout: 'right-sidebar',
      pageName: "Category",
    },
  },
  {
    path: '/posts/:id',
    name: "posts.single",
    component: () => import('../views/pages/Public/Single/single-post.vue'),
    meta: {
      layout: 'default',
      pageName: "post.single",
    },
  },
  // ========== end of Public routes ==============


  // ========== Auth routes ==============
  {
    path: '/register',
    name: 'register',
    meta: {
      pageName: "Register"
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "auth" */ '../views/pages/Auth/Register'),
    beforeEnter: (to, from, next) => {
      // Vue.$log.info(to);
      // Vue.$log.info(from);
      if (store.getters["auth/isAuthenticated"]) {
        next({ name: 'dashboard.overview' });
      } else {
        next();
      }
    },
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "auth" */ '../views/pages/Auth/Login'),
    beforeEnter: (to, from, next) => {
      // Vue.$log.info(to);
      // Vue.$log.info(from);
      if (store.getters["auth/isAuthenticated"]) {
        next({ name: 'dashboard.overview' });
      } else {
        next();
      }
    },
    meta: {
      pageName: "Login",
    }
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "auth" */ '../views/pages/Auth/ForgotPassword'),
    meta: {
      pageName: "Account Recovery",
    }
  },
  {
    path: '/reset-password/:token',
    name: 'resetPassword',
    component: () => import(/* webpackChunkName: "auth" */ '../views/pages/Auth/ResetPassword'),
    meta: {
      pageName: "Reset Password",
    }
  },
  // ========== end of Auth routes ==============


  // ================ Admin routes ===============
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/admin/dashboard.vue'),
    meta: {
      layout: 'dashboard',
      pageName: "Dashboard",
    },
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/isAuthenticated"]) {
        next({ name: 'login' });
      } else {
        next();
      }
    },
    children: [
      {
        path: '',
        name: "dashboard.overview",
        component: () => import('../views/admin/overview.vue'),
        meta: {
          layout: 'dashboard',
          pageName: "Dashboard",
        },
      },
      {
        path: 'about',
        name: "dashboard.about",
        component: () => import('../views/admin/about.vue'),
        meta: {
          layout: 'dashboard',
          pageName: "About",
        },
      },
      {
        path: 'posts/',
        component: () => import('../views/admin/post/index.vue'),
        meta: {
          layout: 'dashboard',
          pageName: "All Posts",
        },
        children: [
          {
            path: '',
            name: 'posts.all',
            component: () => import('../views/admin/post/all-posts.vue'),
            meta: {
              layout: 'dashboard',
              pageName: "All Posts",
              postType: 'post',
              ability: ['index', 'post'],
            },
            beforeEnter: checkDashboardAbility,
          }, {
            path: 'add',
            name: 'posts.add',
            component: () => import('../views/admin/post/add-post.vue'),
            meta: {
              layout: 'dashboard',
              pageName: "Add Post",
              postType: 'post',
              ability: ['create', 'post'],
            },
            beforeEnter: checkDashboardAbility,
          },
          {
            path: 'edit/:id',
            name: 'posts.edit',
            component: () => import('../views/admin/post/add-post.vue'),
            meta: {
              layout: 'dashboard',
              pageName: "Edit Post",
              postType: 'post',
              ability: ['edit', 'post'],
            },
            beforeEnter: checkDashboardAbility,
          },
        ]
      },
      {
        path: 'articles/',
        component: () => import('../views/admin/article/index.vue'),
        meta: {
          layout: 'dashboard',
        },
        children: [
          {
            path: '',
            name: 'articles.all',
            component: () => import('../views/admin/post/all-posts.vue'),
            meta: {
              layout: 'dashboard',
              pageName: "All Articles",
              postType: 'article',
              ability: ['index', 'article'],
            },
            beforeEnter: checkDashboardAbility,
          },
          {
            path: 'add',
            name: 'articles.add',
            component: () => import('../views/admin/post/add-post.vue'),
            meta: {
              layout: 'dashboard',
              pageName: "Add Article",
              postType: 'article',
              ability: ['create', 'article'],
            },
            beforeEnter: checkDashboardAbility,
          },
          {
            path: 'edit/:id',
            name: 'articles.edit',
            component: () => import('../views/admin/post/add-post.vue'),
            meta: {
              layout: 'dashboard',
              pageName: "Edit Article",
              postType: 'article',
              ability: ['edit', 'article'],
            },
            beforeEnter: checkDashboardAbility,
          },
        ]
      },
      {
        path: 'papers/',
        name: 'papers',
        component: () => import('../views/admin/paper/index.vue'),
        meta: {
          layout: 'dashboard',
        },
        children: [
          {
            path: '',
            name: 'papers.all',
            component: () => import('../views/admin/post/all-posts.vue'),
            meta: {
              layout: 'dashboard',
              pageName: "All Paper Works",
              postType: 'paper',
              ability: ['index', 'paper'],
            },
            beforeEnter: checkDashboardAbility,
          },
          {
            path: 'add',
            name: 'papers.add',
            component: () => import('../views/admin/paper/add-paper.vue'),
            meta: {
              layout: 'dashboard',
              pageName: "Add Paper",
              ability: ['create', 'paper'],
            },
            beforeEnter: checkDashboardAbility,
          },
          {
            path: 'edit/:id',
            name: 'papers.edit',
            component: () => import('../views/admin/paper/add-paper.vue'),
            meta: {
              layout: 'dashboard',
              pageName: "Edit Paper",
              ability: ['edit', 'paper'],
            },
            beforeEnter: checkDashboardAbility,
          },
        ]
      },
      {
        path: 'projects/',
        component: () => import('../views/admin/project/index.vue'),
        meta: {
          layout: 'dashboard',
          pageName: "All Posts",
        },
        children: [
          {
            path: '',
            name: 'projects.all',
            component: () => import('../views/admin/post/all-posts.vue'),
            meta: {
              layout: 'dashboard',
              pageName: "All Projects",
              postType: 'project',
              ability: ['index', 'project'],
            },
            beforeEnter: checkDashboardAbility,
          },
          {
            path: 'add',
            name: 'projects.add',
            component: () => import('../views/admin/post/add-post.vue'),
            meta: {
              layout: 'dashboard',
              pageName: "Add Project",
              postType: 'project',
              ability: ['create', 'project'],
            },
            beforeEnter: checkDashboardAbility,
          },
          {
            path: 'edit/:id',
            name: 'projects.edit',
            component: () => import('../views/admin/post/add-post.vue'),
            meta: {
              layout: 'dashboard',
              pageName: "Edit Project",
              postType: 'project',
              ability: ['edit', 'project'],
            },
            beforeEnter: checkDashboardAbility,
          },
        ]
      },

      // Files routes
      {
        path: 'media',
        name: 'dashboard.media',
        component: () => import('../views/admin/media.vue'),
        meta: {
          layout: 'dashboard',
          pageName: "Files and Media",
          ability: ['index', 'file'],
        },
        beforeEnter: checkDashboardAbility,
      },

      // Taxonomy routes
      {
        path: '/dashboard/categories',
        name: "dashboard.categories",
        component: () => import('../views/admin/taxonomy/taxonomy.vue'),
        meta: {
          layout: 'dashboard',
          pageName: "Categories",
          pageNameSingular: "Category",
          ability: ['index', 'term'],
        },
        beforeEnter: checkDashboardAbility,
      },

      // have to work on this later!!!
      // {
      //   path: 'taxonomy/tag',
      //   name: "dashboard.tags",
      //   component: () => import('../views/admin/taxonomy/taxonomy.vue'),
      //   meta: {
      //     layout: 'dashboard',
      //     pageName: "Tags",
      //     pageNameSingular: "Tag",
      //     ability: ['index', 'term'],
      //   },
      //   beforeEnter: checkDashboardAbility,
      // },

      // Comments routes
      {
        path: 'comments',
        name: "dashboard.comments",
        component: () => import('../views/admin/comment/comments-all.vue'),
        meta: {
          layout: 'dashboard',
          pageName: "Comments",
          ability: ['index', 'comment'],
        },
        beforeEnter: checkDashboardAbility,
      },

      // User routes
      {
        path: 'members',
        name: 'users.all',
        component: () => import('../views/admin/user/all.vue'),
        meta: {
          layout: 'dashboard',
          pageName: "All Users",
          ability: ['index', 'user'],
        },
        beforeEnter: checkDashboardAbility,
      },
      {
        path: 'my-profile',
        name: 'users.profile',
        component: () => import('../views/admin/user/profile.vue'),
        meta: {
          layout: 'dashboard',
          pageName: "Account Settings",
          ability: ['edit', 'profile'],
        },
        beforeEnter: checkDashboardAbility,
      },

      // {
      //   // UserProfile will be rendered inside User's <router-view>
      //   path: 'members',
      //   component: () => import('../views/admin/user/all.vue'),
      //   meta: {
      //     layout: 'dashboard',
      //     pageName: "Users",
      //   },
      //   children: [
      //     {
      //       path: 'add',
      //       name: 'users.add',
      //       component: () => import('../views/admin/user/add.vue'),
      //       meta: {
      //         layout: 'dashboard',
      //         pageName: "Add User",
      //       },
      //     },
      //   ]
      // },

      {
        path: 'settings',
        component: () => import('../views/admin/settings.vue'),
        name: 'dashboard.settings',
        meta: {
          layout: 'dashboard',
          pageName: "Settings",
          ability: ['index', 'settings'],
        },
        beforeEnter: checkDashboardAbility,
      },

      // ========== contact routes ==============      
      {
        path: 'response/all',
        component: () => import('../views/admin/response/response-all.vue'),
        name: 'dashboard.response',
        meta: {
          layout: 'dashboard',
          pageName: "Responses",
          ability: ['index', 'contact'],
        },
        beforeEnter: checkDashboardAbility,
      },
      // ======= end of contact routes ========== 

      // ======== certificate routes ============
      {
        path: 'certificate/',
        component: () => import('../views/admin/certificate/index.vue'),
        meta: {
          layout: 'dashboard',
          pageName: "Mailer",
        },
        children: [
          {
            path: '',
            name: 'certificates.all',
            component: () => import('../views/admin/certificate/certificate-all.vue'),
            meta: {
              layout: 'dashboard',
              pageName: "All Certificates",
              ability: ['index', 'certificate'],
            },
            beforeEnter: checkDashboardAbility,
          },
          {
            path: 'add',
            name: 'certificate.add',
            component: () => import('../views/admin/certificate/certificate-add.vue'),
            meta: {
              layout: 'dashboard',
              pageName: "Add Certificate",
              ability: ['send', 'certificate'],
            },
            beforeEnter: checkDashboardAbility,
          },
        ]
      },
      // ===== end of certificate routes =======
      
      // ========== mailer routes ==============     
      {
        path: 'mail/',
        component: () => import('../views/admin/mailer/index.vue'),
        meta: {
          layout: 'dashboard',
          pageName: "Mailer",
        },
        children: [
          {
            path: '',
            name: 'mails.all',
            component: () => import('../views/admin/mailer/mail-all.vue'),
            meta: {
              layout: 'dashboard',
              pageName: "All Mails",
              ability: ['index', 'mail'],
            },
            beforeEnter: checkDashboardAbility,
          },
          {
            path: 'send',
            name: 'mail.send',
            component: () => import('../views/admin/mailer/mail-send.vue'),
            meta: {
              layout: 'dashboard',
              pageName: "Send Mail",
              ability: ['send', 'mail'],
            },
            beforeEnter: checkDashboardAbility,
          },
        ]
      },
      // ======= end of mailer routes ========== 
    ]
  },
  // ========== end of dashboard routes ==============


  // ================== 404 route ====================
  {
    path: '*',
    name: '404',
    meta: {
      pageName: "404"
    },
    component: () => import('../views/pages/Public/404.vue')
  },

  // ========== Test routes =============
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/test.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) { /* eslint-disable-line */
    // Vue.$log.info(savedPosition);
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      }
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})

// router.beforeResolve( async (to, from, next) => {
//   let ability = to.meta['ability'] || false;
//   if (ability) {
//     if (await Vue.$can(ability[0], ability[1])) {
//       next();
//     } else {
//       next({ name: '404' });
//     }
//   } else {
//     next();
//   }
// })

export default router
