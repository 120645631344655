<template>
    <div class="bg-transparent py-3">
        <div>
            <p class="mb-0 pl-3">
                <small>
                    © {{new Date().getFullYear()}} | Alpha Science Lab | Developed by
                    <a :href="author.website" target="blank">{{author.name}}</a>
                </small>
            </p>
        </div>
    </div>
</template>
<script>
import { author } from "../../../package.json"
export default {
    data(){
        return {
            author
        }
    }
}
</script>