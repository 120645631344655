<template>
    <div>
        <DefaultNavbar class="sticky-top"></DefaultNavbar>

        <Transition name="fade" mode="out-in">
            <!-- Content here -->
            <router-view v-slot="{ Component }">
                <transition name="slide">
                    <component :is="Component" />
                </transition>
            </router-view>
        </Transition>

        <DefaultFooter></DefaultFooter>
    </div>
</template>

<script>
import DefaultNavbar from "@/components/navbars/default-navbar.vue";
import DefaultFooter from "@/components/footers/default-footer.vue";

export default {
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: null,
        // all titles will be injected into this template
        titleTemplate: "%s | Alpha Science Lab",
    },
    components: {
        DefaultNavbar,
        DefaultFooter,
    },
};
</script>

<style>
</style>