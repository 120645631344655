<!-- 
	This is the main page of the application, the layout component is used here,
	and the router-view is passed to it.
	Layout component is dynamically declared based on the layout for each route,
	specified in routes list router/index.js .
 -->

<template>
    <div id="app">
        <b-overlay
            :show="isLoading"
            rounded="sm"
            spinner-type="grow"
            spinner-variant="primary"
            opacity="0.6"
            variant="transparent"
            :no-wrap="isLoading"
            :fixed="true"
            z-index="9999"
            blur="1rem"
        >
            <component :is="layout">
                <router-view />
            </component>
        </b-overlay>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isLoading: true,
        };
    },
    computed: {
        // Sets components name based on current route's specified layout, defaults to
        // <layout-default></layout-default> component.
        layout() {
            return (
                "layout-" + (this.$route.meta.layout || "default").toLowerCase()
            );
        },
    },
    methods: {
        checkTokens() {
            return this.$store.getters["auth/getAuthToken"] ? true : false;
        },
    },
    beforeMount() {
        // if token found, then validate.
        if (this.checkTokens()) {
            this.$store
                .dispatch("auth/identifyUser", window.location.href)
                .then((res) => {
                    this.$log.info(res);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        } else {
            this.isLoading = false;
        }
    },
};
</script>

<style lang="scss">
@import "~@/assets/scss/vendors/bootstrap-vue/index";

.bg-gray-100 {
    background-color: $gray-100;
}

.bg-gray-200 {
    background-color: $gray-200;
}

.bg-gray-300 {
    background-color: $gray-300;
}

.w-5 {
    width: 5%;
}

.w-10 {
    width: 10%;
}

.w-15 {
    width: 20%;
}

.w-20 {
    width: 20%;
}

.w-30 {
    width: 30%;
}

.w-40 {
    width: 40%;
}

.w-50 {
    width: 50%;
}

.w-60 {
    width: 60%;
}

.w-70 {
    width: 70%;
}

.w-80 {
    width: 70%;
}

.w-90 {
    width: 70%;
}

.cursor-pointer {
    cursor: pointer;
}

.custom-social-link {
    color: $gray-600 !important;
    transition: 200ms;
}

.custom-social-link:hover {
    // font-size: larger;
    color: $primary !important;
}

.display-5 {
    font-size: 2.7rem;
    font-weight: 300;
    line-height: 1.2;
}

.display-6 {
    font-size: 2rem;
    font-weight: 300;
    line-height: 1.2;
}

.display-7 {
    font-size: 1.3rem;
    font-weight: 300;
    line-height: 1.2;
}

.display-8 {
    font-size: 0.9rem;
    font-weight: 300;
    line-height: 1.2;
}

.text-teal {
    color: $teal;
}

.text-orange {
    color: $orange;
}

.text-pink {
    color: $pink;
}

.text-purple {
    color: $purple;
}

.text-indigo {
    color: $indigo;
}

.text-small {
    font-size: small !important;
}

.text-smaller {
    font-size: smaller !important;
}

.text-x-smaller {
    font-size: x-small !important;
}

.text-xx-smaller {
    font-size: xx-small !important;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.1s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
    transition: all 0.25s ease-out;
}

.slide-enter-from {
    opacity: 0;
    transform: translateX(30px);
}

.slide-leave-to {
    opacity: 0;
    transform: translateX(-30px);
}

.statsIconWrap{
    box-shadow: $box-shadow-sm;
}
</style>
