// import Vue from "vue";
import QRCode from "qrcode";
import store from "@/store";
import ability from "@/services/ability";
/**
 * Check if has permission and belongs to the user.
 *
 * @param {Number} post_user_id
 * @param {String} operation
 * @param {String} postType
 */
export const hasAbilityTo = (post_user_id, operation, postType) => {
  let authUserId = store.getters["auth/getAuthenticatedUser"].id;
  if (ability.can(`${operation}-other`, postType)) {
    return true;
  }

  return ability.can(operation, postType) && post_user_id == authUserId;
};

export const toggleFullscreen = (elem) => {
  elem = elem || document.documentElement;

  if (
    !document.fullscreenElement &&
    !document.mozFullScreenElement &&
    !document.webkitFullscreenElement &&
    !document.msFullscreenElement
  ) {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
    }
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  }
};

export const processName = (user) => {
  let theName = null;
  if (!user.user_profile.first_name) {
    theName = user.username;
  } else {
    theName = user.user_profile.first_name;
    if (user.user_profile.last_name) {
      theName += " " + user.user_profile.last_name;
    }
  }

  return theName;
};

export const processRank = (user) => {
  // if ex member, show rank as alumni.
  let theRank = user.is_alumni ? "alumni" : user.user_profile.rank;

  return theRank;
};

export const processExactRank = (user) => {
  let rank = user.user_profile.rank;
  if (rank !== "founder" && user.is_alumni == 1) {
    return "ex-" + rank;
  }
  return rank;
};

/**
 * Truncates a string by a given length.
 *
 * @param {String} str
 * @param {Number} length
 * @param {String} replace
 * @returns {String}
 */
export const textTruncate = (str, length = 25, replace = "...") => {
  let theString;
  if (!str) {
    return theString;
  } else if (str.length < length) {
    return str;
  } else {
    theString = str.substring(0, length).trim();
    theString += replace;
  }
  return theString;
};

/**
 * Get the index of an item in a array by a key,
 *
 * @param {String | Number} value
 * @param {Array} dataArray
 * @param {String} key
 */
export const findIndexByKey = (value, dataArray, key = "id") => {
  return dataArray.findIndex((item) => {
    return item[key] === value;
  });
};

/**
 * Make slug from string.
 *
 * @param {String} title
 * @returns
 */
export const stringToSlug = (title) => {
  let maxLen = 30;
  let parsedSlug = title.replace(/\s+/gi, "-").toLowerCase();
  const regex = new RegExp("^[a-z0-9]+(?:-[a-z0-9]+)*$");
  if (!regex.test(parsedSlug)) {
    for (let i = 0; i < parsedSlug.length; i++) {
      // Finding the character whose
      // ASCII value fall under this
      // range
      if (
        parsedSlug[i] < "A" ||
        (parsedSlug[i] > "Z" && parsedSlug[i] < "a") ||
        parsedSlug[i] > "z"
      ) {
        if (parsedSlug[i] === "-") {
          continue;
        }
        // erase function to erase
        // the character
        parsedSlug = parsedSlug.substring(0, i) + parsedSlug.substring(i + 1);
        i--;
      }
    }
  }
  if (parsedSlug.length > maxLen) {
    parsedSlug = parsedSlug.slice(0, maxLen);
  }
  return parsedSlug;
};

/**
 * Finds the parent's name by parent id.
 *
 * @param {Number} parent The id of parent
 * @param {Array} array Search in
 * @param {String} key The key to search
 * @param {String} prop Property to retrieve.
 */
export const processParent = (parent, array, key = "id", prop = "name") => {
  let obj = array.find((item) => {
    return item[key] == parent;
  });

  if (obj) {
    return obj[prop];
  } else {
    return "";
  }
};

/**
 * Generates a qr code for a given string.
 * Uses the qrcode package.
 * See: https://www.npmjs.com/package/qrcode
 *
 * @param {String} data Data to covert into QR code.
 * @param {Object} options QR code configurations.
 * @return {String}
 */
export const QRCodeGenerate = (
  data,
  options = {
    errorCorrectionLevel: "H",
    type: "image/png",
    quality: 0.3,
    margin: 1,
    width: 200,
    color: {
      dark: "#000000",
      light: "#ffffff",
    },
  }
) => {
  return new Promise((resolve, reject) => {
    QRCode.toDataURL(data, options)
      .then((url) => {
        resolve(url);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 * Downloads the base64 encoded image.
 *
 * @param {String} dataUrl Base64 encoded data.
 * @param {String} fileName The download filename.
 */
export const QRCodeDownload = (dataUrl, fileName = "qr-code") => {
  const downloadLink = document.createElement("a");
  downloadLink.href = dataUrl;
  downloadLink.download = fileName;
  downloadLink.click();
};

/**
 * Copies text to the clipboard.
 *
 * @param {String} data Text to be copied.
 */
export const copyToClipboard = (data) => {
  navigator.clipboard.writeText(data);
};

/**
 * Get meta value by key in a array.
 *
 * @param {Array} metas Data to search in.
 * @param {String} key Search by.
 */
export const getMetaByKey = (metas, key) => {
  let value = "";
  for (let i = 0; i < metas.length; i++) {
    metas[i].meta_key === key;
    if (metas[i].meta_key === key) {
      value = metas[i].meta_value;
      break;
    }
  }
  return value;
};

/**
 * Converts bytes to megabytes
 *
 * @param {Number} bytes Bytes to convert
 * @returns Megabytes
 */
export const bytesToMB = (bytes) => {
  return parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
};

/**
 * Converts bytes to MB, KB, GB Etc.
 *
 * @param {Number} bytes Bytes to convert.
 * @returns MB, KB, GB, TB etc
 */
export const bytesToSize = (bytes) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (!bytes || bytes === 0) return "n/a";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) return `${bytes} ${sizes[i]})`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

export const uniqueid = () => {
  // always start with a letter (for DOM friendlyness)
  var idstr = String.fromCharCode(Math.floor(Math.random() * 25 + 65));
  do {
    // between numbers and characters (48 is 0 and 90 is Z (42-48 = 90)
    var ascicode = Math.floor(Math.random() * 42 + 48);
    if (ascicode < 58 || ascicode > 64) {
      // exclude all chars between : (58) and @ (64)
      idstr += String.fromCharCode(ascicode);
    }
  } while (idstr.length < 32);

  return idstr;
};

/**
 * Check whether the
 *
 * @param {string} compare Role to compare
 * @param {string | null} against Role to compare against
 * @returns {boolean}
 */
export const checkIfHigherRole = (
  compare,
  against = store.getters["auth/getUserRoles"][0]
) => {
  const defaultRoles = store.getters["auth/getDefaultRoles"];

  return defaultRoles.indexOf(against) > defaultRoles.indexOf(compare);
};
