import axios from "../../plugins/axios";
import Vue from 'vue';
export default {
	namespaced: true,

	state: {

	},

	mutations: {

	},

	actions: {
		index(context, { params }) {
			Vue.$log.info('Code came here');
			return new Promise((resolve, reject) => {
				axios
					.get("admin/mails", {
						params
					})
					.then((res) => {
						Vue.$log.info(res);
						resolve(res);
					})
					.catch((err) => reject(err));
			});
		},

		send(context, body) {
			Vue.$log.info('Code came here');
			return new Promise((resolve, reject) => {
				axios
					.post("admin/mails/send", body)
					.then((res) => {
						Vue.$log.info(res);
						resolve(res);
					})
					.catch((err) => reject(err));
			});
		},

		delete(context, id) {
			Vue.$log.info(id);
			return new Promise((resolve, reject) => {
				axios
					.delete(`admin/mails/${id}`)
					.then((res) => {
						Vue.$log.info(res);
						resolve(res);
					})
					.catch((err) => reject(err));
			});
		}
	},

	getters: {

	},
};
