<template>
    <b-modal
        v-model="show"
        centered
        size="xl"
        id="media-box-modal"
        title="File Explorer"
        body-class="px-3 m-0 py-0"
    >
        <MediaTabs :is_popup="true" />

        <template #modal-footer>
            <b-button
                variant="primary"
                class="px-5"
                @click="selectMedia"
                :disabled="!isInsertAble"
                ><font-awesome-icon icon="fa-solid fa-arrow-right-long" class="mr-2"/> Select</b-button
            >
        </template>
    </b-modal>
</template>

<script>
import EventBus from "@/eventBus";
import MediaTabs from "@/components/admin/media/media-tabs.vue";
export default {
    components: {
        MediaTabs,
    },

    data() {
        return {
            show: false,
            tabIndex: 0,
        };
    },

    computed: {
        getSelectedFIle() {
            return this.$store.getters["file/get_file"] || null;
        },

        isInsertAble() {
            return this.getSelectedFIle ? true : false;
        },
    },

    methods: {
        shoeModal() {
            this.show = !this.show;
        },

        selectMedia() {
            this.$store
                .dispatch("file/insertFile")
                .then((res) => {
                    this.$log.info(res);
                    this.closeModal();
                })
                .catch((err) => {
                    alert(err);
                });
        },

        closeModal() {
            this.$bvModal.hide("media-box-modal");
        },
    },

    mounted() {
        EventBus.$on("toggle-file-modal", this.shoeModal);
    },
};
</script>

<style>
</style>