import axios from "../../plugins/axios";
import Vue from 'vue';
export default {
    namespaced: true,

    state: {
       
    },

    mutations: {

    },

    actions: {
        registerTeam(context, body){
            Vue.$log.info('code came here', body);
            return new Promise((resolve, reject) => {
                axios
                .post("/event/register", body)
                .then((res) => {
                    if (res.data.isSuccess) {
                        resolve(res.data);
                        Vue.$log.info(res);
                    } else {
                        reject(res.data);
                        Vue.$log.info(res);
                    }
                })
                .catch((err) => reject(err));
            })
        }
    },

    getters: {

    },
};
