<template>
	<!-- <div :style="{ height: height, width:'auto' }"> -->
		<img :src="logoUrl" alt="Site Logo" :height="height" :width="width">
	<!-- </div> -->

</template>

<script>
export default {
	props: {
		height: {
			type: String,
			default: '50px'
		},
		width: {
			type: String,
			default: 'auto'
		}
	},
	data() {
		return {
			logoUrl: require('@/assets/logo/site-logo.svg'),
		}
	},
};
</script>

<style>

</style>