import axios from "../../plugins/axios";
import Vue from 'vue';
export default {
    namespaced: true,

    state: {

    },

    mutations: {

    },

    actions: {
        getMember(context, username) {
            return new Promise((resolve, reject) => {
                axios.get(`members/${username}`).then((res) => {
                    Vue.$log.info(res);
                    resolve(res);
                }).catch((err) => {
                    Vue.$log.info(err);
                    reject(err)
                });
            });
        },

        updateAccountStatus(context, body) {
			return new Promise((resolve, reject) => {
				axios.patch(`admin/members/update-account-status/${body.id}`, body).then((res) => {
					if (res) {
						resolve(res)
						Vue.$log.info(res);
                    } else {
                        resolve(res)
                    }
                }).catch((err) => reject(err));
            })
        },

        updateMemberRole(context, {id, body}) {
			return new Promise((resolve, reject) => {
				axios.patch(`admin/members/update-member-role/${id}`, body).then((res) => {
					if (res) {
						resolve(res)
						Vue.$log.info(res);
                    } else {
                        resolve(res)
                    }
                }).catch((err) => reject(err));
            })
        },

        getASLAdvisors(){
            return new Promise((resolve, reject) => {
                axios.get(`members/advisors`).then((res) => {
                    resolve(res);
                }).catch((err) => {
                    reject(err)
                });
            });
        }
    },

    getters: {

    },
};
