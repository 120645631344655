import { render, staticRenderFns } from "./dual-sidebar.vue?vue&type=template&id=7de13626&"
import script from "./dual-sidebar.vue?vue&type=script&lang=js&"
export * from "./dual-sidebar.vue?vue&type=script&lang=js&"
import style0 from "./dual-sidebar.vue?vue&type=style&index=0&id=7de13626&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports