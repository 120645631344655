import '@babel/polyfill';
import 'mutationobserver-shim';

import Vue from 'vue';
import './plugins/bootstrap-vue';
import vuelidate from "vuelidate";

import App from './App.vue';
import router from './router/router.js';
import store from './store';
import VueLogger from 'vuejs-logger';

import { abilitiesPlugin } from '@casl/vue';
import ability from './services/ability';

// icon libraries
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far} from '@fortawesome/free-regular-svg-icons';
import { fab} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(fas, far, fab);
Vue.component('fa', FontAwesomeIcon);
Vue.component('font-awesome-icon', FontAwesomeIcon);

const isProduction = process.env.NODE_ENV === 'production';

import SiteLogo from "./components/others/site-logo.vue";
import DefaultLayout from './views/layouts/default.vue';
import DashboardLayout from './views/layouts/dashboard.vue';
import RightSidebarLayout from './views/layouts/right-sidebar.vue';
import DualSidebarLayout from './views/layouts/dual-sidebar.vue';
import SectionLayout from "@/components/public/page-sections/section-layout.vue";
import PageUnderConstruction from '@/components/others/page-under-construction';
import NotFoundBanner from "@/components/others/not-found-banner.vue";
Vue.config.productionTip = true;

Vue.component("SiteLogo", SiteLogo);
Vue.component("layout-default", DefaultLayout);
Vue.component("layout-dashboard", DashboardLayout);
Vue.component("layout-right-sidebar", RightSidebarLayout);
Vue.component("layout-dual-sidebar", DualSidebarLayout);
Vue.component("SectionLayout", SectionLayout);
Vue.component("PageUnderConstruction", PageUnderConstruction);
Vue.component("NotFoundBanner", NotFoundBanner);

Vue.use(vuelidate);

Vue.use(VueLogger, {
  isEnabled: true,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true
});

Vue.use(abilitiesPlugin, ability, {
  useGlobalProperties: true
})


// Vue.use(abilitiesPlugin, ability);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
