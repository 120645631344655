import axios from "../../plugins/axios";
import Vue from 'vue';
// import ability from "@/services/ability";
export default {
    namespaced: true,

    state: {

    },

    mutations: {

    },

    actions: {
        getPosts(context, { params }) {
            Vue.$log.info('Code came here');
            return new Promise((resolve, reject) => {
                axios
                    .get("posts/all", {
                        params
                    })
                    .then((res) => {
                        Vue.$log.info(res);
                        resolve(res);
                    })
                    .catch((err) => reject(err));
            });
        },
        
        
        getPostsByTaxonomy(context, { params, taxonomy }) {
            Vue.$log.info('Code came here');
            return new Promise((resolve, reject) => {
                axios
                    .get(`posts/${taxonomy.type}/${taxonomy.term}`, {
                        params
                    })
                    .then((res) => {
                        Vue.$log.info(res);
                        resolve(res);
                    })
                    .catch((err) => reject(err));
            });
        },

        getRecentPosts(context, { params }) {
            Vue.$log.info('Code came here')
            return new Promise((resolve, reject) => {
                axios
                    .get(`posts/recent-posts`, { params })
                    .then((res) => {
                        Vue.$log.info(res);
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err)
                    });
            });
        },

        getPopularPosts(context, { params }) {
            Vue.$log.info('Code came here')
            return new Promise((resolve, reject) => {
                axios
                    .get(`posts/popular-posts`, { params })
                    .then((res) => {
                        Vue.$log.info(res);
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err)
                    });
            });
        },

        getAll(context, { params }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`admin/posts`, { params })
                    .then((res) => {
                        Vue.$log.warn(res);
                        resolve(res);
                    })
                    .catch((err) => reject(err));
            });
        },


        /**
         * For Admin
         * 
         * @param {*} c 
         * @param {*} id 
         * @returns 
         */
        getPostById(context, id) {
            Vue.$log.info("get the post by id: " + id);
            return new Promise((resolve, reject) => {
                axios
                    .get(`posts/${id}`)
                    .then((res) => {
                        Vue.$log.info(res);
                        resolve(res);
                    })
                    .catch((err) => reject(err));
            });
        },

        getLockedPostContent(content, {id, body}){
            Vue.$log.info('Post add action', body)
            return new Promise((resolve, reject) => {
                axios
                    .post(`posts/unlock-content/${id}`, body)
                    .then((res) => {
                        Vue.$log.info(res);
                        resolve(res);
                    })
                    .catch((err) => {
                        Vue.$log.info(err);
                        reject(err)
                    });
            });
        },

        getPostEdit(context, id) {
            Vue.$log.info("get the post by id: " + id);
            return new Promise((resolve, reject) => {
                axios
                    .get(`admin/posts/edit/${id}`)
                    .then((res) => {
                        Vue.$log.info(res);
                        resolve(res);
                    })
                    .catch((err) => reject(err));
            });
        },

        getStats() {
            Vue.$log.info("get post stats");
            return new Promise((resolve, reject) => {
                axios
                    .get(`admin/posts/stats`)
                    .then((res) => {
                        Vue.$log.info(res);
                        resolve(res);
                    })
                    .catch((err) => reject(err));
            });

        },

        /**
         * 
         * @param {Object} context the store object
         * @param {Object} body the payload
         * @returns Promise
         */
        add(context, body) {
            Vue.$log.info('Post add action', body)
            return new Promise((resolve, reject) => {
                axios
                    .post("admin/posts/add", body)
                    .then((res) => {
                        Vue.$log.info(res);
                        resolve(res);
                    })
                    .catch((err) => {
                        Vue.$log.info(err);
                        reject(err)
                    });
            });
        },

        /**
         * Updates Post
         * 
         * @param {*} context 
         * @param {*} body 
         * @returns 
         */
        update(context, { id, body }) {
            Vue.$log.info('update post', body)
            return new Promise((resolve, reject) => {
                axios
                    .patch(`admin/posts/update/${id}`, body)
                    .then((res) => {
                        Vue.$log.info(res);
                        resolve(res);
                    })
                    .catch((err) => {
                        Vue.$log.info(err);
                        reject(err)
                    });
            });
        },

        updatePostApproval(context, {id, body}){
            Vue.$log.info('updatePostApproval post', body)
            return new Promise((resolve, reject) => {
                axios
                    .patch(`admin/posts/update-post-approval/${id}`, body)
                    .then((res) => {
                        Vue.$log.info(res);
                        resolve(res);
                    })
                    .catch((err) => {
                        Vue.$log.info(err);
                        reject(err)
                    });
            });
        },

        /**
         * Delete post
         * 
         * @param {*} context Store Object
         * @param {*} id Post Id
         * @returns 
         */
        delete(context, id) {
            // Vue.$log.info(ability.can('delete-other', 'post'));
            // if (ability.can('delete-other', 'post')) {
            //     return;
            // }

            return new Promise((resolve, reject) => {
                axios
                    .delete(`admin/posts/delete/${id}`)
                    .then((res) => {
                        Vue.$log.info(res);
                        resolve(res);
                    })
                    .catch((err) => {
                        Vue.$log.info(err);
                        reject(err)
                    });
            });
        },
    },

    getters: {

    },
};
