import axios from "../../plugins/axios";
import Vue from 'vue';
export default {
    namespaced: true,

    state: {

    },

    mutations: {

    },

    actions: {
        getChangeLogs() {
			Vue.$log.info('Code came here, Change Log action');
            return new Promise((resolve, reject) => {
                axios
                    .get("admin/change-log")
                    .then((res) => {
                        Vue.$log.info(res);
                        resolve(res);
                    })
                    .catch((err) => reject(err));
            });
        },
    },

    getters: {

    },
};
