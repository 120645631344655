<template>
    <div class="position-relative">
        <DefaultNavbar></DefaultNavbar>

        <div class="container">
            <div>
                <h1 class="my-5 display-4">
                    <span>{{ this.$route.meta.pageName }}</span><span class="text-capitalize">{{ this.$route.params.name ?
                        ': "' + this.$route.params.name + '"' : '' }}</span>
                </h1>
            </div>
            <div class="row">
                <div class="col-lg-9 col-md-8">
                    <!-- Content here -->
                    <router-view :key="$route.path"></router-view>
                </div>
                <div class="col-lg-3 col-md-4 pr-md-0 pl-md-2">
                    <SideBar>
                        <SearchFormWidget />
                        <br>
                        <TaxonomiesListWidget title="Categories" />
                        <br>
                        <PopularPosts postType="article" title="Popular Articles" :limit="10"/>
                        <br>
                    </SideBar>
                </div>
            </div>
        </div>

        <DefaultFooter></DefaultFooter>
    </div>
</template>
  
<script>
import DefaultNavbar from "@/components/navbars/default-navbar.vue";
import DefaultFooter from "@/components/footers/default-footer.vue";
import SideBar from "@/components/public/sidebars/default-sidebar.vue";

export default {
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: null,
        // all titles will be injected into this template
        titleTemplate: '%s | Alpha Science Lab'
    },
    components: {
        DefaultNavbar,
        DefaultFooter,
        SideBar,
        TaxonomiesListWidget: () => import("@/components/public/widgets/taxonomies-list.vue"),
        // RecentPosts: () => import("@/components/public/widgets/recent-posts.vue"),
        PopularPosts: () => import("@/components/public/widgets/popular-posts.vue"),

        SearchFormWidget: () => import("@/components/public/widgets/search-form.vue"),
    },
};
</script>
  
<style></style>