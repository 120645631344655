<template>
    <div class="pt-4 footer-bg px-3">
        <div class="d-flex flex-column align-items-center">
            <div class="my-3">
                <!-- <fa icon="fa-solid fa-paper-plane" class="text-primary" style="font-size: 40px" /> -->
                <SiteLogo height="60px" />
            </div>
            <div class="text-center display-6 text-capitalize text-light">
                Creativity Starts From Belief
            </div>
            <!-- <div class="display-8">Creativity Starts From Belief</div> -->
            <small>
                <b-navbar
                    type="dark"
                    variant="transparent"
                    class="text-uppercase"
                >
                    <b-navbar-nav class="d-md-flex d-none align-items-center">
                        <b-nav-item :to="{ name: 'home' }">Home</b-nav-item>
                        <b-nav-item :to="{ name: 'feed' }">Feed</b-nav-item>
                        <b-nav-item :to="{ name: 'articles' }"
                            >Articles</b-nav-item
                        >
                        <b-nav-item :to="{ name: 'about' }">About</b-nav-item>
                        <b-nav-item :to="{ name: 'members.all' }"
                            >Members</b-nav-item
                        >
                        <b-nav-item :to="{ name: 'alumni.all' }"
                            >Alumni</b-nav-item
                        >
                        <b-nav-item :to="{ name: 'hallOfFame' }"
                            >Hall Of Fame</b-nav-item
                        >
                        <b-nav-item :to="{ name: 'contact' }"
                            >Contact</b-nav-item
                        >
                    </b-navbar-nav>
                    <b-navbar-nav class="d-flex align-items-center">
                        <template v-if="!isAuthenticated">
                            <b-nav-item :to="{ name: 'login' }"
                                >Login</b-nav-item
                            >
                            <b-nav-item :to="{ name: 'register' }"
                                >Register</b-nav-item
                            >
                        </template>
                        <template v-else>
                            <b-nav-item :to="{ name: 'dashboard.overview' }"
                                >Dashboard</b-nav-item
                            >
                        </template>
                    </b-navbar-nav>
                </b-navbar>
            </small>
            <div class="mb-2 text-nowrap text-muted text-small text-center">
                Visit Our Official Facebook Page
            </div>
            <div class="row no-gutters">
                <div class="col-auto">
                    <a href="https://www.facebook.com/Alpha.Science.Lab">
                        <fa
                            icon="fa-brands fa-facebook-square"
                            style="font-size: 2rem"
                        />
                    </a>
                </div>
            </div>

            <div class="text-center text-muted mb-0 py-4 w-100">
                <small class="">
                    © {{ new Date().getFullYear() }} | Alpha Science Lab |
                    Developed by
                    <a class="text-muted default-footer-link" target="_blank" :href="author.website">{{
                        author.name
                    }}</a>
                    <!-- & -->
                    <!-- <a class="text-muted default-footer-link" href="https://www.facebook.com/groups/689091805205348" target="_blank">ASL CODERS</a> -->
                </small>
            </div>
        </div>
    </div>
</template>

<script>
import { author } from "../../../package.json";
export default {
    name: "default-footer",
    components: {},
    data() {
        return {
            author,
        };
    },
    computed: {
        isAuthenticated() {
            return this.$store.getters["auth/isAuthenticated"];
        },
    },
};
</script>

<style scoped>
.footer-bg {
    background-color: #001123;
}

.default-footer-link:hover{
    color: #eeeeee !important;
    text-decoration: none !important;
    transition: 200ms;
}
</style>