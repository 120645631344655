import axios from "../../plugins/axios";
import Vue from 'vue';
export default {
    namespaced: true,

    state: {
        users: [
            {
                avatar: '',
                username: 'john',
                name: 'John Doe',
                email: 'john@email.com',
                joinedAt: '2022-10-10',
            }
        ]
    },

    mutations: {
        GET_ALL_USERS(state, data) {
            state.user = data;
        }
    },

    actions: {
        getMyProfile() {
            return new Promise((resolve, reject) => {
                axios.get("admin/user/my-profile").then((res) => {
                    if (res) {
                        Vue.$log.info(res);
                        resolve(res.data)
                    } else {
                        reject(res)
                    }
                }).catch((err) => reject(err));
            });
        },
        
        getAllUsers() {
            return new Promise((resolve, reject) => {
                axios.get("admin/user/all").then((res) => {
                    if (res) {
                        Vue.$log.info(res);
                        resolve(res);
                    } else {
                        resolve(res)
                    }
                }).catch((err) => reject(err));
            });
        },

        getAllMembers() {
            return new Promise((resolve, reject) => {
                axios.get("/members").then((res) => {
                    if (res) {
                        resolve(res.data);
                    } else {
                        reject(res.data)
                    }
                }).catch((err) => reject(err));
            });
        },
        
        getAllAlumni() {
            return new Promise((resolve, reject) => {
                axios.get("/members/alumni").then((res) => {
                    if (res) {
                        resolve(res.data);
                    } else {
                        reject(res.data)
                    }
                }).catch((err) => reject(err));
            });
        },

        updateMyProfile(context, body) {
            return new Promise((resolve, reject) => {
                axios.patch("admin/user/update-my-profile", body).then((res) => {
                    if (res) {
                        Vue.$log.info(res.data);
                        resolve(res)
                    } else {
                        resolve(res)
                    }
                }).catch((err) => reject(err));
            })
        },
        
        updatePassword(context, body) {
            return new Promise((resolve, reject) => {
                axios.patch("admin/user/update-my-password", body).then((res) => {
                    if (res) {
                        Vue.$log.info(res.data);
                        resolve(res)
                    } else {
                        resolve(res)
                    }
                }).catch((err) => reject(err));
            })
        },

        updateProfilePic(context, body) {
            return new Promise((resolve, reject) => {
                axios.patch(`admin/user/update-profile-pic/${body.id}`, body).then((res) => {
                    if (res) {
                        Vue.$log.info(res.data);
                        resolve(res)
                    } else {
                        resolve(res)
                    }
                }).catch((err) => reject(err));
            })
        },        
        
        updateCoverPic(context, body) {
            return new Promise((resolve, reject) => {
                axios.patch(`admin/user/update-cover-pic/${body.id}`, body).then((res) => {
                    if (res) {
                        Vue.$log.info(res.data);
                        resolve(res)
                    } else {
                        resolve(res)
                    }
                }).catch((err) => reject(err));
            })
        },

        getStats(){
            return new Promise((resolve, reject) => {
                axios.get(`admin/user/stats`).then((res) => {
                    if (res) {
                        Vue.$log.info(res.data);
                        resolve(res)
                    } else {
                        resolve(res)
                    }
                }).catch((err) => reject(err));
            })
        }
        
    },

    getters: {
        getAllUsers(state) {
            return state.users;
        },
    },
};
