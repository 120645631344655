import axios from "../../plugins/axios";
import Vue from 'vue';
export default {
    namespaced: true,

    state: {

    },

    mutations: {

    },

    actions: {
        getAll() {
            return new Promise((resolve, reject) => {
                axios
                    .get("admin/contacts")
                    .then((res) => {
                        Vue.$log.info(res);
                        resolve(res);
                    })
                    .catch((err) => reject(err));
            });
        },

        send(context, body) {
            Vue.$log.info('Code came here');
            return new Promise((resolve, reject) => {
                axios
                    .post("contacts/send", body)
                    .then((res) => {
                        Vue.$log.info(res);
                        if(res.data.status){
                            resolve(res);
                        }
                    })
                    .catch((err) => reject(err));
            });
        },

        delete(context, id) {
            Vue.$log.info(id);
            return new Promise((resolve, reject) => {
                axios
                    .delete(`admin/contacts/${id}`)
                    .then((res) => {
                        Vue.$log.info(res);
                        resolve(res);
                    })
                    .catch((err) => reject(err));
            });
        }
    },

    getters: {

    },
};
