import { render, staticRenderFns } from "./default-footer.vue?vue&type=template&id=5396ee9f&scoped=true&"
import script from "./default-footer.vue?vue&type=script&lang=js&"
export * from "./default-footer.vue?vue&type=script&lang=js&"
import style0 from "./default-footer.vue?vue&type=style&index=0&id=5396ee9f&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5396ee9f",
  null
  
)

export default component.exports