export default {
  namespaced: true,

  state: {
    whatIsASL: "Alpha Science Lab is a student-based scientific research lab located at Mymensingh Engineering College. It is a technology-based lab that gives scientific & IT-enabled products and services to people. It also provides free courses, seminars, project making guidance to the students to prove their engineering proficiency in every aspect of life. Our goal is to achieve efficiency and simplicity in every work through the innovative use of local technology and create a hub for scientific research.",
    foundationInfo: "Alpha Science lab was founded by Nahiyan Al Rahman Oli (4th batch, EEE) on 31 July 2012 with a short investment and small space in Amor Ekushe Hall.",
  },

  mutations: {

  },

  actions: {

  },

  getters: {
    getWhatIsASL(state) {
      return state.whatIsASL;
    },

    getFoundationInfo(state) {
      return state.foundationInfo;
    }
  },
};
