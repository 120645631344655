<template>
    <section :class="SectionClass" class="" :id="SectionId">
        <div :class="headerWrapClass">
            <div :class="preHeaderClass" class="text-center">
                <slot name="preHeader">
                    <!--  -->
                </slot>
            </div>
            <div :class="headerClass" class="text-center">
                <slot name="header">
                    <!--  -->
                </slot>
            </div>
            <div :class="subHeaderClass" class="text-center">
                <slot name="subHeader">
                    <!--  -->
                </slot>
            </div>
        </div>
        <main :class="contentClass">
            <slot>
                <!--  -->
            </slot>
        </main>
        <div :class="footerClass" class="text-center">
            <slot name="footer">
                <!--  -->
            </slot>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        SectionClass: {
            type: String,
            default: null,
            required: false,
        },
        SectionId: {
            type: String,
            default: null,
            required: false,
        },
        headerWrapClass: {
            type: String,
        },        
		preHeaderClass: {
            type: String,
        },
        headerClass: {
            type: String,
        },
        subHeaderClass: {
            type: String,
        },
        footerClass: {
            type: String,
        },
        contentClass: {
            type: String,
        },
    },
    data() {
        return {};
    },
};
</script>

<style>
</style>