<template>
  <div class="wrap">
    <img :src="illustration" alt="Page is under construction!">
    <p>Sorry for the Inconvenience!</p>
    <h1>This Page is Under Construction.</h1>
  </div>
</template>

<script>
export default {
  name: "page-under-construction.vue",
  data() {
    return {
      illustration: require('@/assets/img/illustrations/under-maintenance.svg')
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
}
</style>